import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Booking.css'; // Assuming the CSS is in a file named PaymentsPage.css

const PaymentHistory = ({ summary }) => {

  const [payments, setPayments] = useState([]);
  const [amountPaid, setAmountPaid] = useState(0);
 

  const getPayments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/banking/get_payment`,
        {
          headers: {
            auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
          },
        }
      );
      const payments = response.data.payments;
      console.log("Payments", payments);
      setPayments(payments);

      // Calculate total amount paid
      const totalPaid = payments.reduce((sum, payment) => sum + payment.amount_paid, 0);
      setAmountPaid(totalPaid);

      // Calculate remaining amount
   
    } catch (error) {
      console.error('Error fetching payments:', error);
    }
  };

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <div className="gym-display-container">
      <h1 className="card-header">Payment Details</h1>
      <div className="summary-widget">
        
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Payment ID</th>
              <th>Amount Paid</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={payment.id}>
                <td>{index + 1}</td>
                <td>{payment.id}</td>
                <td>₹{payment.amount_paid}</td>
                <td>{new Date(payment.paid_on).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentHistory;
