import React, { useEffect, useState } from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSignOutAlt, faBars, faQrcode, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import logo from "../img/logo3.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const loginId = Cookies.get('auth');
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/verify-token`,
          {},
          {
            headers: {
              Authorization: loginId
            }
          }
        );

        if (response.data.message !== 'Token is valid') {
          Cookies.remove('auth');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        Cookies.remove('auth');
        navigate('/login');
      }
    };

    if (loginId) {
      verifyToken();
    }
  }, [loginId, navigate]);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    Cookies.remove('auth');
    navigate('/login');
  };

  const handleTabClick = (path) => {
    setActiveTab(path);
    navigate(path);
    setMenuOpen(false);
  };

  const navLinks = [
    { path: '/profile', label: 'Profile' },
    { path: '/equipment', label: 'My Equipment' },
    { path: '/slots', label: 'My Slots' },
    { path: '/gallery', label: 'My Gallery' },
    { path: '/booking', label: 'My Payments' },
    { path: '/bookings', label: 'My Bookings' },
    { path: '/banking', label: 'Bank Account' },
    { path: '/scan', label: 'Scan' },
  ];

  return (
    <header className="header">
      <div className="container">
        <div className="logo-container">
          <Link className="fancy-link" to="/">
            <img src={logo} height="50" width="100" alt="Logo" />
          </Link>
        </div>

        {loginId && (
          <button className="nav-link scan-icon" onClick={() => handleTabClick('/scan')}>
            <FontAwesomeIcon icon={faQrcode} />
          </button>
        )}

        <button className="menu-button" onClick={handleMenuToggle}>
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
        </button>

        <nav className={`header-nav ${menuOpen ? 'open' : ''}`}>
          {loginId ? (
            <>
              {navLinks.map((link) => (
                <Link
                  key={link.path}
                  to={link.path}
                  className={`nav-link ${activeTab === link.path ? 'active' : ''}`}
                  onClick={() => handleTabClick(link.path)}
                >
                  {link.label}
                </Link>
              ))}
              <button className="logout-button" onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className={`nav-link btn-login ${activeTab === '/login' ? 'active' : ''}`} onClick={() => handleTabClick('/login')}>Login</Link>
              <Link to="/register" className={`nav-link btn-register ${activeTab === '/register' ? 'active' : ''}`} onClick={() => handleTabClick('/register')}>Register Gym</Link>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;