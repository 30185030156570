import React, { useState } from 'react';
import Header from '../components/Header';
import ReactScan from '../components/Scanner';
import axios from "axios";
import "./Scanner.css";
import successSound from '../sound/success.mpeg';
import failureSound from '../sound/fail.mpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const QRCodeReader = () => {
    const [scanResult, setScanResult] = useState(null);
    const [messages, setMessages] = useState([]);
    const [scanStatus, setScanStatus] = useState('');
    const [scan, setScan] = useState(true);

    const handleOnScan = async (result) => {
        setScan(false);
        if (result) {
            const bookingId = result[0].rawValue;

            try {
                const response = await axios.get(bookingId, {
                    headers: { 'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") }
                });
                setMessages([response.data.message || 'Booking Found!']);
                setScanStatus('success');
                new Audio(successSound).play(); // Play success sound
            } catch (error) {
                const errorMsg = error.response ? error.response.data.message : 'An error occurred';
                setMessages([errorMsg]);
                setScanStatus('fail');
                new Audio(failureSound).play(); // Play failure sound
            }
            setScanResult(bookingId);
            
        }
    };

    const handleNewScan = () => {
        setScan(true);
        setScanResult(null);
        setMessages([]);
        setScanStatus('');
    };

    return (
        <div className="scanner-page">
            <Header />
            <div className="scanner-content">
                <h1>QR Code Scanner</h1>
                {scan && <ReactScan onScan={handleOnScan} />}
                <div className="scan-feedback">
                    {scanResult && (
                        <div className={`feedback-message ${scanStatus}`}>
                            {scanStatus === 'success' ? (
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className="feedback-icon success" />
                                    <h2>Success!</h2>
                                    <p>{messages[0]}</p>
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faTimesCircle} className="feedback-icon fail" />
                                    <h2>Scan Failed</h2>
                                    <p>{messages[0]}</p>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="scan-actions">
                    <button onClick={handleNewScan} className="new-scan-button">
                        New Scan
                    </button>
                </div>
            </div>
        </div>
    );
};

export default QRCodeReader;
