import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Booking.css';
import Header from '../components/Header';
import axios from 'axios';

const UserBookingPage = () => {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSlotTime, setSelectedSlotTime] = useState('all');

    const getAllBooking = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/booking`, {
                headers: {
                    auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
                },
            });
            const bookings = response.data.Booking;
            setBookings(bookings);
            setFilteredBookings(bookings);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    useEffect(() => {
        getAllBooking();
    }, []);

    useEffect(() => {
        filterBookings();
    }, [selectedDate, selectedSlotTime, bookings]);

    const filterBookings = () => {
        let filtered = bookings;

        if (selectedDate) {
            filtered = filtered.filter((booking) => booking.bookingDate === selectedDate);
        }

        if (selectedSlotTime !== 'all') {
            filtered = filtered.filter((booking) => booking.slotStartTime === selectedSlotTime);
        }

        setFilteredBookings(filtered);
    };

    const refreshData = () => {
        getAllBooking();
    };


    const getUniqueSlotTimes = () => {
        return [...new Set(bookings.map((booking) => booking.slotStartTime))];
    };
    

    return (
        <>
            <Header />
            <div className="container-fluid gym-display-container">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2 className="text-primary">User Bookings</h2>
                    <button
                        className="btn btn-primary"
                        onClick={refreshData}
                        title="Refresh Booking Data"
                    >
                        Refresh
                    </button>
                </div>

                <div className="row mb-3">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <label htmlFor="dateSelect" className="form-label">Select Booking Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="dateSelect"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="slotSelect" className="form-label">Select Slot Timing</label>
                                <select
                                    className="form-select"
                                    id="slotSelect"
                                    value={selectedSlotTime}
                                    onChange={(e) => setSelectedSlotTime(e.target.value)}
                                >
                                    <option value="all">All Slots</option>
                                    {getUniqueSlotTimes().map((slotTime, index) => (
                                        <option key={index} value={slotTime}>
                                            {slotTime}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                <div className="card shadow-lg mb-4">
                    <div className="card-body">
                        {filteredBookings.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-striped table-hover table-bordered">
                                    <thead className="table-dark">
                                        <tr>
                                            <th scope="col">Booking ID</th>
                                            <th scope="col">User Full Name</th>
                                            <th scope="col">User Mobile Number</th>
                                            <th scope="col">Booking Date</th>
                                            <th scope="col">Visited</th>
                                            <th scope="col">Gym Rating</th>
                                            <th scope="col">Slot Start Time</th>
                                            <th scope="col">Subscription Price</th>
                                            <th scope="col">Payment Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredBookings.map((booking, index) => (
                                            <tr key={index}>
                                                <th scope="row">{booking.bookingId}</th>
                                                <td>{booking.userFullName}</td>
                                                <td>{booking.userMobileNumber}</td>
                                                <td>{new Date(booking.bookingDate).toLocaleDateString()}</td>
                                                <td>
                                                    {booking.visited ? (
                                                        <span className="badge bg-success">Visited</span>
                                                    ) : (
                                                        <span className="badge bg-danger">Not Visited</span>
                                                    )}
                                                </td>
                                                <td>{booking.gymRating}</td>
                                                <td>{booking.slotStartTime}</td>
                                                <td>INR {booking.subscriptionPrice}</td>
                                                <td>{booking.isPaid ? <span className="badge bg-success">Paid</span> : <span className="badge bg-danger">Not Paid</span>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-center">No bookings found for the selected filters.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserBookingPage;
