import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Modal, Button, Form, Container, Row, Col, ListGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BankAccountModification.css";

const BankAccountModificationPage = () => {
  const [bankData, setBankData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [confirmBankAccount, setConfirmBankAccount] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [enteredCode, setEnteredCode] = useState("");
  const [ifscSuggestions, setIfscSuggestions] = useState([]);
  const [isLoadingIfsc, setIsLoadingIfsc] = useState(false);

  useEffect(() => {
    fetchBankData();
  }, []);

  const fetchBankData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/banking/get`,
        {
          headers: {
            auth: document.cookie.replace(
              /(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
          },
        }
      );
      setBankData(response.data);
    } catch (error) {
      console.error("Error fetching bank account data:", error);
    }
  };

  const handleShowEditModal = () => {
    setModalData({
      bankAccountName: bankData?.bankAccountName || "",
      bankAccountNumber: bankData?.bankAccountNumber || "",
      bankName: bankData?.bankName || "",
      bankIFSC: bankData?.bankIFSC || "",
    });
    setConfirmBankAccount(bankData?.bankAccountNumber || "");
    setCodeSent(false);
    setShowEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSendCode = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/banking/send-verification-code`,
        { },
        {
          headers: {
            auth: document.cookie.replace(
              /(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
          },
        }
      );
      toast.success("Verification code sent to your email!");
      setVerificationCode(response.data.code);
     
    } catch (error) {
      console.log("error is", error);
      toast.error("Error sending verification code.");
    }
    setCodeSent(true);
  };

  const handleIfscSearch = async (value) => {
    setModalData((prevData) => ({ ...prevData, bankIFSC: value }));
    if (value.length < 4) {
      setIfscSuggestions([]);
      return;
    }

    setIsLoadingIfsc(true);
    try {
      const response = await axios.get(`https://ifsc.razorpay.com/${value}`);
      const bankDetails = response.data;

      // Update IFSC suggestions and bank name
      setIfscSuggestions([bankDetails]);
      setModalData((prevData) => ({
        ...prevData,
        bankName: bankDetails.BANK, // Automatically set the bank name
      }));
    } catch (error) {
      setIfscSuggestions([]); // Clear suggestions if no valid results
      setModalData((prevData) => ({
        ...prevData,
        bankName: "", // Clear the bank name if IFSC is invalid
      }));
    } finally {
      setIsLoadingIfsc(false);
    }
  };

  const handleIfscSelect = (ifscCode) => {
    setModalData((prevData) => ({
      ...prevData,
      bankIFSC: ifscCode, // Set only the IFSC code
    }));
    setIfscSuggestions([]); // Clear suggestions after selecting an IFSC code
  };

  const handleSave = async () => {
    if (modalData.bankAccountNumber !== confirmBankAccount) {
      toast.error("Bank Account Numbers do not match!");
      return;
    }

    try {
      modalData.code = enteredCode;
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/banking/update`,
        modalData,
        {
          headers: {
            auth: document.cookie.replace(
              /(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
          },
        }
      );
      setShowEditModal(false);
      toast.success("Bank Account Details Updated Successfully");
      fetchBankData();
    } catch (error) {
      toast.error("Error updating bank account data.");
    }
  };

  return (
    <>
      <Header />
      <Container className="bank-account-page mt-5">
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Card className="shadow-lg mb-4">
              <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                <h2>Bank Account Details</h2>
                <Button variant="light" onClick={handleShowEditModal}>
                  Modify Details
                </Button>
              </Card.Header>
              <Card.Body>
                <Card.Title>Account Holder Name</Card.Title>
                <Card.Text>{bankData?.bankAccountName || "NA"}</Card.Text>
                <Card.Title>Bank Account Number</Card.Title>
                <Card.Text>{bankData?.bankAccountNumber || "NA"}</Card.Text>
                <Card.Title>Bank Name</Card.Title>
                <Card.Text>{bankData?.bankName || "NA"}</Card.Text>
                <Card.Title>IFSC Code</Card.Title>
                <Card.Text>{bankData?.bankIFSC || "NA"}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Bank Account Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="bankAccountName">
                <Form.Label>Account Holder Name</Form.Label>
                <Form.Control
                  type="text"
                  name="bankAccountName"
                  value={modalData.bankAccountName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="bankAccountNumber" className="mt-3">
                <Form.Label>Bank Account Number</Form.Label>
                <Form.Control
                  type="text"
                  name="bankAccountNumber"
                  value={modalData.bankAccountNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="confirmBankAccountNumber" className="mt-3">
                <Form.Label>Confirm Bank Account Number</Form.Label>
                <Form.Control
                  type="text"
                  value={confirmBankAccount}
                  onChange={(e) => setConfirmBankAccount(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="bankIFSC" className="mt-3">
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  type="text"
                  name="bankIFSC"
                  value={modalData.bankIFSC}
                  onChange={(e) => handleIfscSearch(e.target.value)}
                />
                {ifscSuggestions.length > 0 && (
                  <ListGroup className="mt-2">
                    {ifscSuggestions.map((suggestion, index) => (
                      <ListGroup.Item
                        key={index}
                        action
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event propagation
                          handleIfscSelect(suggestion.IFSC); // Only set IFSC code
                        }}
                      >
                        {suggestion.BANK} - {suggestion.BRANCH} ({suggestion.IFSC})
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Form.Group>
              <Form.Group controlId="bankName" className="mt-3">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  name="bankName"
                  value={modalData.bankName}
                  onChange={handleChange}
                  readOnly
                />
              </Form.Group>
              {codeSent && (
                <Form.Group controlId="verificationCode" className="mt-3">
                  <Form.Label>Enter Verification Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={enteredCode}
                    onChange={(e) => setEnteredCode(e.target.value)}
                  />
                </Form.Group>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Close
            </Button>
            {!codeSent ? (
              <Button variant="primary" onClick={handleSendCode}>
                Send Code
              </Button>
            ) : (
              <Button variant="success" onClick={handleSave}>
                Update Details
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <ToastContainer position="top-right" />
      </Container>
    </>
  );
};

export default BankAccountModificationPage;
