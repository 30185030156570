import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginWithPin.css';
import Header from '../components/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Get query parameters
  const gymUniqueId = searchParams.get('gym_unique_id');
  const token = searchParams.get('token');
  localStorage.setItem("auth_data", token);
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    gym_id: gymUniqueId,
  });
  const [error, setError] = useState(null);

  // Extract gym_unique_id and token from query parameters
 



  // Pre-fill gym_id if gym_unique_id exists in query params
  useEffect(() => {
    
    handleSubmit();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (e) => {

    try {
      
      // Include gym_unique_id and token in the request
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/ui/login`, {
        gym_id: loginData.gym_id,
        token, // Include token if available
      });

      const { token: authToken } = response.data;

      // Set token in the cookie
      document.cookie = `auth=${authToken}; path=/;`;

      // Redirect to the profile page after successful login
      navigate('/admin_booking');
    } catch (error) {
      toast.error('Invalid email or password. Please try again.');
      if (error.response?.status === 403) {
        navigate('/verify-email');
      }
    }
  };

  return (
    <div className="login-page">
      <Header />
      <div className="hero-overlay">
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card shadow-lg">
                <div className="card-body">
                  <h2 className="text-center mb-4 text-light">Login</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label className="text-light">Gym Id</label>
                      <input
                        type="text"
                        className="form-control"
                        name="gym_id"
                        value={loginData.gym_id}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter Gym Id"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-light">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={loginData.email}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-light">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={loginData.password}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter your password"
                      />
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <button type="submit" className="btn btn-warning w-100 mb-2">
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminLogin;
